import React, { useEffect, lazy, Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Loading from './pages/Loading/Loading';
import './App.css';
import './styles/styles.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactPixel from 'react-facebook-pixel';


const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const CottagesPage = lazy(() => import('./pages/CottagesPage/CottagesPage'));
const GenplanPage = lazy(() => import('./pages/GenplanPage/GenplanPage'));
const PodyhPage = lazy(() => import('./pages/PodyhPage'));
const NeboPage = lazy(() => import('./pages/NeboPage'));
const SolarOnePage = lazy(() => import('./pages/SolarOnePage'));
const SolarTwoPage = lazy(() => import('./pages/SolarTwoPage'));
const SpokiyPage = lazy(() => import('./pages/SpokiyPage'));
const DyvoPage = lazy(() => import('./pages/DyvoPage'));


const PIXEL_ID = "890862312999212";
ReactPixel.init(PIXEL_ID);


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      // once: true,
    });
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/cottages" element={<CottagesPage />} />
          <Route path="/ganplan" element={<GenplanPage />} />
          <Route path="/cottages/podyh" element={<PodyhPage />} />
          <Route path="/cottages/nebo" element={<NeboPage />} />
          <Route path="/cottages/solar3.1" element={<SolarOnePage />} />
          <Route path="/cottages/solar3.2" element={<SolarTwoPage />} />
          <Route path="/cottages/svitanok" element={<SpokiyPage />} />
          <Route path="/cottages/dyvo" element={<DyvoPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
